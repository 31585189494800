function Submitted() {
    return(
        <div className="Submitted" style={{
            margin:"20%"
        }}>
            <h1>Data Submitted Succesfully </h1>
            <h1>Thank You for selecting CSI CHAPTER</h1>
        </div>
    );
}

export default Submitted;